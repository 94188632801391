import React from "react";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";

import Layout from "../../components/layout";
import TopHeader from "../../components/top_header";
import Footer from "../../components/footer";

import "../../styles/fthinking.scss";

import HeaderLogoImage from "../../images/common/header_logo@2x.png";
import LineFiveDotImage from "../../images/common/line_5-dot@2x.png";
import HakaseFiveMathMind from "../../images/common/img_hakase_5-mathmind.png";
import StepImage from "../../images/common/img_step@2x.png";
import ReverseImage from "../../images/common/img_reverse@2x.png";
import CreateImage from "../../images/common/img_create@2x.png";
import KnockImage from "../../images/common/img_knock@2x.png";
import ScanImage from "../../images/common/img_scan@2x.png";

export default function FiveThinking(props) {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <Layout>
      <div className="fthinking">
        <TopHeader headerLogoImage={HeaderLogoImage} />

        <div id="five_thinking_column">
          <div className="content_full_box">
            <div className="content_box">
              <div className="inner">
                <div className="headerx">
                  <div className="title">{t("mmind.fthinking.ft_title")}</div>
                </div>
                <div
                  className={`description dynamic_line_height ${
                    currentLanguage === "en" ? "line_height_narrow" : ""
                  }`}
                >
                  <Trans i18nKey="mmind.fthinking.ft_description" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="five_thinking_carousel_column">
          <div className="content_full_box">
            <div className="content_box">
              <div className="inner">
                <div id="type_detail">
                  <div className="carousel_item step">
                    <img alt="" className="result_image" src={StepImage} />
                    <h1 className="result_title step">
                      <Trans i18nKey="step.title" />
                    </h1>
                    <p className="result_subtitle step">
                      <Trans i18nKey="step.subtitle" />
                    </p>
                    <p
                      className={`result_detail dynamic_line_height ${
                        currentLanguage === "en" ? "line_height_narrow" : ""
                      }`}
                    >
                      <Trans i18nKey="step.detail" />
                    </p>
                  </div>
                  <div className="carousel_item reverse">
                    <img alt="" className="result_image" src={ReverseImage} />
                    <h1 className="result_title reverse">
                      <Trans i18nKey="reverse.title" />
                    </h1>
                    <p className="result_subtitle reverse">
                      <Trans i18nKey="reverse.subtitle" />
                    </p>
                    <p
                      className={`result_detail dynamic_line_height ${
                        currentLanguage === "en" ? "line_height_narrow" : ""
                      }`}
                    >
                      <Trans i18nKey="reverse.detail" />
                    </p>
                  </div>
                  <div className="carousel_item create">
                    <img alt="" className="result_image" src={CreateImage} />
                    <h1 className="result_title create">
                      <Trans i18nKey="create.title" />
                    </h1>
                    <p className="result_subtitle create">
                      <Trans i18nKey="create.subtitle" />
                    </p>
                    <p
                      className={`result_detail dynamic_line_height ${
                        currentLanguage === "en" ? "line_height_narrow" : ""
                      }`}
                    >
                      <Trans i18nKey="create.detail" />
                    </p>
                  </div>
                  <div className="carousel_item knock">
                    <img alt="" className="result_image" src={KnockImage} />
                    <h1 className="result_title knock">
                      <Trans i18nKey="knock.title" />
                    </h1>
                    <p className="result_subtitle knock">
                      <Trans i18nKey="knock.subtitle" />
                    </p>
                    <p
                      className={`result_detail dynamic_line_height ${
                        currentLanguage === "en" ? "line_height_narrow" : ""
                      }`}
                    >
                      <Trans i18nKey="knock.detail" />
                    </p>
                  </div>
                  <div className="carousel_item scan">
                    <img alt="" className="result_image" src={ScanImage} />
                    <h1 className="result_title scan">
                      <Trans i18nKey="scan.title" />
                    </h1>
                    <p className="result_subtitle scan">
                      <Trans i18nKey="scan.subtitle" />
                    </p>
                    <p
                      className={`result_detail dynamic_line_height ${
                        currentLanguage === "en" ? "line_height_narrow" : ""
                      }`}
                    >
                      <Trans i18nKey="scan.detail" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="five_thiking_information_column">
          <div className="content_full_box">
            <div className="content_box">
              <div className="inner">
                <div
                  className={`preface dynamic_line_height ${
                    currentLanguage === "en" ? "line_height_narrow" : ""
                  }`}
                >
                  <Trans i18nKey="mmind.fthinking.fti_preface" />
                </div>
                <div className="delimiter">
                  <img
                    className="img-responsive center-block delimiter_img"
                    alt=""
                    src={LineFiveDotImage}
                  />
                </div>
                <h1 className="title">
                  <Trans i18nKey="mmind.fthinking.fti_title" />
                </h1>
                <div className="animated_image_frame">
                  <img
                    alt=""
                    className="animated_image"
                    src={HakaseFiveMathMind}
                  />
                </div>
                <div
                  className={`description dynamic_line_height ${
                    currentLanguage === "en" ? "line_height_narrow" : ""
                  }`}
                >
                  <Trans i18nKey="mmind.fthinking.fti_description" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </Layout>
  );
}
